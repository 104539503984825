import { http } from '@/lib/http'
import {
  BusinessDataType,
  OpenApiAccessIdType,
  BusinessDataTypeItemType,
  BusinessDataSourceItemType,
  BusinessDataSourceListType
} from '#/BusinessDataTypes'

export default {
  /**
   * 获取集成列表
   * @param {string} entityId 主体id
   */
  getBusinessDataTypeList: (entityId: string) => {
    return http.request<ResponseData<BusinessDataTypeItemType[]>>('get', `/entity/${entityId}/businessDataType`)
  },
  /**
   * 创建businessDataType
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} name
   */
  createBusinessDataType: (entityId: string, data: object) => {
    return http.request<ResponseData<BusinessDataTypeItemType>>('post', `/entity/${entityId}/businessDataType`, {
      data
    })
  },
  /**
   * 修改businessDataType
   * @param {string} entityId 主体id
   * @param {string} businessDataTypeId
   * @param {Object} data
   * @property {string} data.name
   */
  editBusinessDataType: (entityId: string, businessDataTypeId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/businessDataType/${businessDataTypeId}`, { data })
  },
  /**
   * 删除businessDataType
   * @param {string} entityId 主体id
   * @param {string} businessDataTypeId
   * @param {Object} data
   * @property {string} data.deleteJournal 是否删除journal
   */
  deleteBusinessDataType: (entityId: string, businessDataTypeId: string, data: object) => {
    return http.request('delete', `/entity/${entityId}/businessDataType/${businessDataTypeId}`, { data })
  },
  /**
   * 创建 openapi access
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.name 名称
   */
  createOpenApiAccess: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/openApiAccess`, { data })
  },
  /**
   * 获取 openapi access列表
   * @param {string} entityId 主体id
   */
  getOpenApiAccessList: (entityId: string) => {
    return http.request<ResponseData<OpenApiAccessIdType[]>>('get', `/entity/${entityId}/openApiAccess`)
  },
  /**
   * 删除 openapi access
   * @param {string} entityId 主体id
   * @param {string} openApiAccessId
   */
  deleteOpenApiAccess: (entityId: string, openApiAccessId: string) => {
    return http.request('delete', `/entity/${entityId}/openApiAccess/${openApiAccessId}`)
  },
  /**
   * 修改 openapi access
   * @param {string} entityId 主体id
   * @param {string} openApiAccessId
   * @param {Object} data
   * @property {string} data.name 名称
   */
  editOpenApiAccess: (entityId: string, openApiAccessId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/openApiAccess/${openApiAccessId}`, { data })
  },
  /**
   * 获取集成source列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getBusinessDataSourceList: (entityId: string, params: object) => {
    return http.request<ResponseData<BusinessDataSourceListType>>('get', `/entity/${entityId}/businessDataSource`, {
      params
    })
  },
  /**
   * 添加businessData Source
   * @param {string} entityId 主体id
   * @param {string} businessDataTypeId
   * @param {Object} data
   * @property {string} data.name
   * @property {string} data.businessDataFileId
   */
  addBusinessDataSource: (entityId: string, businessDataTypeId: string, data: object) => {
    return http.request<ResponseData<BusinessDataSourceItemType>>(
      'post',
      `/entity/${entityId}/businessDataType/${businessDataTypeId}/businessDataSource`,
      { data }
    )
  },
  /**
   * 删除businessData Source
   * @param {string} entityId 主体id
   * @param {string} businessDataSourceId
   * @param {Object} data
   * @property {string} data.deleteJournal 是否删除journal
   */
  deleteBusinessDataSource: (entityId: string, businessDataSourceId: string, data: object) => {
    return http.request('delete', `/entity/${entityId}/businessDataSource/${businessDataSourceId}`, { data })
  },
  /**
   * 查看原始数据列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} params.status ALL|IDENTIFIED|UNIDENTIFIED
   * @property {string} params.businessDataSourceId
   * @property {number} params.page
   * @property {number} params.limit
   */
  getOriginBusinessDataList: (entityId: string, params: object) => {
    return http.request<ResponseData<BusinessDataType>>('get', `/entity/${entityId}/businessData`, { params })
  },
  /**
   * 获取 business data自动化规则列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getBusinessDataAutomationRuleList: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/businessDataAutomationRule`, { params })
  },
  /**
   * 创建business data自动化规则
   * @param {string} entityId 主体id
   * @param {Object} data
   */
  createBusinessDataAutomationRule: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/businessDataAutomationRule`, { data })
  },
  /**
   * 修改business data自动化规则
   * @param {string} entityId 主体id
   * @param {string} businessDataAutomationRuleId 主体id
   * @param {Object} data
   */
  editBusinessDataAutomationRule: (entityId: string, businessDataAutomationRuleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/businessDataAutomationRule/${businessDataAutomationRuleId}`, {
      data
    })
  },
  /**
   * 修改business data自动化规则启用状态
   * @param {string} entityId 主体id
   * @param {string} businessDataAutomationRuleId 规则id
   * @param {object} data
   * @property {boolean} data.enabled 是否启用
   */
  switchBusinessDataAutomationRuleStatus: (entityId: string, businessDataAutomationRuleId: string, data: object) => {
    return http.request(
      'put',
      `/entity/${entityId}/businessDataAutomationRule/${businessDataAutomationRuleId}/enabled`,
      {
        data
      }
    )
  },
  /**
   * 删除business data自动化规则
   * @param {string} entityId 主体id
   * @param {string} businessDataAutomationRuleId 规则id
   */
  deleteBusinessDataAutomationRule: (entityId: string, businessDataAutomationRuleId: string) => {
    return http.request('delete', `/entity/${entityId}/businessDataAutomationRule/${businessDataAutomationRuleId}`)
  },
  /**
   * 执行单个business data自动化规则
   * @param {string} entityId 主体id
   * @param {string} businessDataAutomationRuleId 规则id
   */
  executeBusinessDataAutomationRule: (entityId: string, businessDataAutomationRuleId: string) => {
    return http.request(
      'post',
      `/entity/${entityId}/businessDataAutomationRule/${businessDataAutomationRuleId}/execute`
    )
  },
  /**
   * 执行所有business data自动化规则
   * @param {string} entityId 主体id
   */
  executeAllBusinessDataAutomationRule: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/executeAllBusinessDataAutomationRule`)
  }
}
